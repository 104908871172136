.gx-app {
  text-align: center;
}

.gx-app-logo {
  height: 100px;
  pointer-events: none;
}

.gx-app-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
}
